<template>
	<div>
		<NavBar :navSelIndex="4"></NavBar>

		<b-container fluid class="tool-bg">
			<b-row class="tool-row">
				<b-col class="tool-col" cols="12">
					<img class="tool-bg-img" src="../../../public/icon/threeDimensional/vr1.jpg" />
				</b-col>
			</b-row>
		</b-container>

		<b-container class="container-t-mode container-t-mode-wrap" fluid>

			<b-row class="category-box">
				<b-col lg="1" lx="1" md="2" sm="4" class="category-title">
					{{$t("styleCategory")}}
				</b-col>
				<b-col lg="11" lx="11" md="10" class="category-item-box">
					<b-row class="category-item-row">
						<div class="category-item-col" @click="changeStyle({categoryId:''})"
							:class="[styleId == ''?'category-item-col-sel':'']">
							{{$t('allCategoryTitle')}}
						</div>
						<div class="category-item-col" :class="[styleId == item.categoryId?'category-item-col-sel':'']"
							v-for="(item,index) in styleCategory" :key="index" @click="changeStyle(item)">
							{{item.name}}
						</div>
					</b-row>
				</b-col>
			</b-row>

			<div class="solution-box video-box">
				<waterfall :col="colCount" :data="designs3dList" class="solution">
					<template>
						<div class="solution-item" v-for="(item,index) in designs3dList" :key="index"
							@click="to3DDetail(item)">
							<div class="solution-item-ehome">
								<img class="solution-img" :src="item.imgPath" />
								<!-- <div class="collection-button" @click.stop="collectionDesign3D(item)">
									<i class="el-icon-star-off" v-if="item.collectionType==0"></i>
									<i class="el-icon-star-on" v-else></i>
								</div> -->
								<div class="solution-name ehome-solution-title-name">
									{{item.worksName}}
									<!-- <span class="solution-name-button"
										@click="toDesignApply">{{$t("designApplyTitle")}}</span> -->
								</div>
								<div class="solution-user-box">
									<!-- <img class="solution-user-avatar" :src="item.designerHeagImgUrl" /> -->
									<div class="solution-designer-name ehome-solution-name">
										{{item.hourseType}} | {{item.styleName}} | {{item.measureArea}}
									</div>
									<span class="solution-name-button"
										@click="toDesignApply">{{$t("designApplyTitle")}}</span>
									<!-- <i class="el-icon-view watch-icon"></i>
									<div class="solution-watch-number ehome-solution-name">{{item.collectionNumber}}</div> -->
								</div>
							</div>
						</div>
					</template>
				</waterfall>
			</div>
			<el-pagination v-if="totalPage >0" :page-count="totalPage" @current-change="changeCurrentPage"
				class="margin-top-80" background layout="prev, pager, next"></el-pagination>
		</b-container>
		<Right></Right>
		<Footer></Footer>
	</div>
</template>

<script>
	import NavBar from "@/components/Navbar.vue";
	import Footer from "@/components/Footer.vue"
	import Right from "@/components/Right.vue"
	import dump from "../../util/dump.js"
	export default {
		components: {
			NavBar,
			Footer,
			Right
		},
		data() {
			return {
				styleName: "",
				colCount: 3,
				styleCategory: [{
					"PHONE_IMG": "",
					"LABEL_ID": 16,
					"SORT": 1,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/da8b21a3-916f-447a-8b60-2e95bb517619.png",
					"LABEL_NAME": "极简"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 15,
					"SORT": 2,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/1bb4a33d-6773-4042-9ec9-bb8b3133cb7a.png",
					"LABEL_NAME": "轻奢"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 53,
					"SORT": 3,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/233d7b53-5eb7-41a8-bee7-dc1a5706f4bb.png",
					"LABEL_NAME": "中式"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 52,
					"SORT": 4,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/4eeb3a66-cb58-49c4-bd29-ac97cb0949b2.png",
					"LABEL_NAME": "美式"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 54,
					"SORT": 5,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/e1a1f2f5-efff-495f-b960-9a687c32bc27.png",
					"LABEL_NAME": "北欧/现代"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 55,
					"SORT": 6,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/5b3615ea-e23d-4ca9-9d15-9680ba2cd0fc.png",
					"LABEL_NAME": "英式"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 56,
					"SORT": 7,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/74f11586-60b1-45ac-a65b-487d94dc953d.png",
					"LABEL_NAME": "欧式/新古典"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 643,
					"SORT": 8,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/bcac77e0-c34a-4391-bb3b-56cd3a3423b4.png",
					"LABEL_NAME": "床垫"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 644,
					"SORT": 9,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2263dba8-4cf7-4655-aeb1-c807d0868bef.png",
					"LABEL_NAME": "户外"
				}, {
					"PHONE_IMG": "",
					"LABEL_ID": 645,
					"SORT": 10,
					"IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/beb15d34-b455-4d42-94c9-bb2f634822a3.png",
					"LABEL_NAME": "儿童"
				}],
				styleId: '',
				designs3dList: [],
				totalPage: 0,
				currentPage: 1,
				pageSize: 12
			}

		},
		created() {
			let width = document.body.clientWidth;
			if (width <= 800 && width > 400) {
				this.colCount = 2;
			} else if (width <= 400) {
				this.colCount = 1;
			}
			this.getStyleList();
			this.getDesign3D(1);
		},
		methods: {
			changeStyle(item) {
				this.styleId = item.categoryId
				this.getDesign3D(this.currentPage);
			},

			collectionDesign3D(info) {
				const that = this;
				if (!dump.checkLogin()) {
					return false;
				}
				let collectionType = 1;
				if (info.collectionType == 1) {
					collectionType = 0;
				}
				that.$api.collection3D({
					worksId: info.worksId,
					collectionType: collectionType
				}).then(res => {
					if (res.status == 100) {
						info.collectionType = collectionType;
						if (info.collectionType == 1) {
							info.collectionNumber++;
						} else if (info.collectionType == 0 && info.collectionNumber > 0) {
							info.collectionNumber--;
						}
					}
				})
			},
			toDesignApply() {
				let data = {
					type: 2,
					link: "https://www.kujiale.com/vc/zhuangdianejia/login"
				}
				dump.link(data);
			},
			to3DDetail(item) {
				item.type = 2;
				item.link = item.threeDUrl;
				dump.link(item);
			},
			changeCurrentPage(info) {
				this.currentPage = info;
				this.getDesign3D(info);
			},
			getDesign3D(currentPage) {
				const that = this;
				that.designs3dList = [];
				that.$api.getDesign3DLIst({
					currentPage: currentPage,
					keyWords: that.keyWords,
					styleId: that.styleId,
					pageSize: that.pageSize
				}).then(res => {
					that.designs3dList = res.data.list;
					that.totalPage = res.data.pages;
					that.currentPage = res.data.currentPage;
				})
			},
			toEPeida() {
				if (!dump.checkLogin()) {
					return false;
				}
				this.$api.epeida({}).then(res => {
					dump.toEPeida(res);
				})
			},
			getStyleList() {
				const that = this;
				that.$api.styleList({}).then(res => {
					that.styleCategory = [];
					res.data.forEach((item) => {
						let tmp = {
							name: item.LABEL_NAME,
							categoryId: item.LABEL_ID,
						}
						that.styleCategory.push(tmp);
					})
					// that.styleCategory.unshift({categoryId:'',name:'全部'})
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	@import "../../assets/scss/style.scss";

	.tool-bg {
		padding-right: 0;
		padding-left: 0;

		.tool-row {
			.tool-col {
				width: 100%;

				.tool-bg-img {
					width: 100%;
					height: auto;
				}

				.tool-img-box {
					position: absolute;
					z-index: 99;
					top: 20%;
					width: 100%;

					.tool-icon-img {
						width: 13.75rem;
						height: auto;
					}

					.tool-title {
						font-size: 2.1875rem;
						letter-spacing: 0.375rem;
						text-align: center;
						color: #FFFFFF;
					}

					.tool-subtitle {
						font-size: 0.8125rem;
						word-spacing: 0.375rem;
						text-align: center;
						color: #FFFFFF;
					}

					.tool-button-box {
						padding-top: 3.75rem;

						.tool-button {
							padding: 0.75rem 0;
							color: #fff;
							font-size: 1.25rem;
							font-weight: bold;
							width: 10.8125rem;
							margin: auto;
							background: $bg-default-s-color;
							cursor: pointer;
						}
					}


				}
			}

		}
	}

	@media (min-width: 1200px) {
		.container-t-mode-wrap {
			padding: 0 6.25rem;
		}

	}

	.container-t-mode-wrap {
		.category-box {
			padding-top: 0.6rem;
			text-align: left;

			.category-title {
				padding: 0.375rem 0.6875rem;
				margin: 0.6rem 0;
				text-align: justify;
				text-align-last: justify;
				max-width: 6rem;
				color: $select-search-category-title;
			}

			.category-item-box {
				.category-item-row {
					align-items: center;
					height: 100%;

					.category-item-col {
						font-size: 0.9375rem;
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: $select-search-category-no;
						padding: 0.0625rem 0.9375rem;
						margin: 0.6rem 1.25rem;
						border-radius: 1.875rem;
						cursor: pointer;
						text-align: center;
					}

					.category-item-col-sel {
						background: $bg-default-s-color;
						color: $select-search-category-s;
					}

					.height-28 {
						height: 1.625rem;

					}

					.category-item-col-brand {
						width: 5.625rem;
						height: 3.125rem;
						margin: 0.6rem 0.3125rem;
						cursor: pointer;

						.category-item-col-brand-img {
							width: 100%;
							height: 100%;
							border: 0.0625rem solid #DCDCDC;
							border-radius: 0.3125rem;
						}

						.category-item-col-brand-img-sel {
							border: 0.0625rem solid $bg-default-s-color;
						}

					}
				}
			}
		}

		.solution-box {
			display: flex;
			flex-direction: row;

			.solution {

				// background: #F7F8F8;
				// box-shadow: 0px 0px 0.9375rem 0px rgba(0, 0, 0, 0.2);
				.vue-waterfall-column {
					@keyframes test {
						40% {
							transform: scale(1.02);
						}

						100% {
							transform: scale(1);
						}
					}

					.solution-item:hover {
						animation-name: test;
						animation-duration: 1s;
						animation-iteration-count: infinite;
						animation-iteration-count: 1;
					}


					.solution-item {
						background: #FFFFFF;
						margin: 0.625rem;
						cursor: pointer;




						.solution-item-ehome {
							box-shadow: 0px 0px 0.375rem 0px rgba(0, 0, 0, 0.2);
							background: #F7F8F8;
							position: relative;

							.collection-button {
								position: absolute;
								display: flex;
								align-items: center;
								justify-content: center;
								width: 2.5rem;
								height: 2.5rem;
								top: 0.5rem;
								right: 0.5rem;
								z-index: 9999;
								background: #FFFFFF;
								border-radius: 0.375rem;
								opacity: 0;
								// border: 0.0625rem solid #F0F7FF;
							}

							&:hover {
								.collection-button {
									opacity: 1;
								}
							}

							.mode-img {
								width: 100%;
								object-fit: cover;
							}

							.solution-img {
								max-width: 100%;
								height: 25.75rem;
								z-index: 9998;
							}

							.solution-name {
								padding-top: 0.875rem;
								padding-left: 0.6875rem;
								text-align: left;
								width: 100%;
							}

							.solution-name-button {
								border-radius: 0.9375rem;
								background: $bg-default-s-color;
								padding: 0.25rem 1.125rem;
								margin-left: auto;
								position: absolute;
								right: 0.6rem;
								color: #FFFFFF;
								// box-shadow: -1px 5px 15px 1px $bg-default-s-color;
							}


							.solution-user-box {
								padding: 0.275rem 0.6875rem 0.4375rem 0.6875rem;
								display: flex;
								align-items: center;

								.solution-user-avatar {
									width: 1.8125rem;
									height: 1.8125rem;
									border-radius: 50%;
								}

								.solution-designer-name {
									padding-left: 0.3125rem;
									max-width: 80%;
									overflow: hidden;
									margin: auto 0;
								}

								.solution-designer-name-mode {
									padding-left: 0.3125rem;
									max-width: 70%;
									overflow: hidden;
									margin: auto 0;
								}

								.ehome-solution-name {
									color: #666;
									font-size: 14px;
									text-align: left;
								}

								.solution-name-button {
									position: absolute;
									bottom: 0.5rem;
									border-radius: 1.4rem;
									background: $bg-default-s-color;
									padding: 0.45rem 1.425rem;
									margin-left: auto;
									position: absolute;
									right: 0.6rem;
									color: #FFFFFF;
								}

								.watch-icon {
									margin-left: auto;
								}

								.icon-padding {
									padding-left: 0.375rem;
								}

								.solution-watch-number {
									padding-left: 0.375rem;
									margin: auto 0;
								}

							}
						}
					}
				}

			}

		}


		/deep/ .el-pagination {
			margin: 3.75rem 0;

			.el-pager {
				li:not(.disabled).active {
					background-color: $bg-default-s-color;
					color: #FFF;
				}

				li:not(.disabled):hover {
					color: $bg-default-s-color;
				}

				li:not(.disabled).active:hover {
					color: #FFF;
				}
			}
		}
	}
</style>
